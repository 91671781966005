<script>
import axios from 'axios'

export default {
  name: 'LoginApp',
  data() {
    return {
      cid: '',
      password: '',
      showErrorDialog: false,
      showErrorMessage: '',
      isSendingRequest: false
    }
  },
  computed: {
    cidRules() {
      const numberRegexp = new RegExp(/^[0-9]+$/)

      return [
        (v) => !!v || 'Client ID required', // cid is required,
        (v) => !!v.match(numberRegexp) || 'CLient ID can contain only numbers'
      ]
    },
    passwordRules() {
      return [(v) => !!v || 'Password is required']
    }
  },
  methods: {
    setErrorData(error = false, message = '') {
      this.showErrorDialog = error
      this.showErrorMessage = message
    },
    login() {
      if (!this.$refs.form.validate()) {
        return
      }

      const { cid, password } = this
      const formData = new FormData()

      formData.append('username', cid)
      formData.append('password', password)

      this.isSendingRequest = true

      return axios
        .post('/login/login', formData)
        .then(() => {
          window.location.href = '/'
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.setErrorData(true, error.response.data.message)
          }
          this.isSendingRequest = false
        })
    }
  }
}
</script>
<template>
  <v-app>
    <v-main tag="div" class="background">
      <v-container class="fill-height justify-center flex-column">
        <img src="/logo_MM_400_white.png" alt="" class="mb-4 logo" />
        <v-card :width="$vuetify.breakpoint.xsOnly ? '100%' : '400px'">
          <v-card-title class="card-title">Login</v-card-title>
          <v-form ref="form" lazy-validation class="pa-4">
            <v-text-field
              v-model="cid"
              :rules="cidRules"
              label="Client ID"
              required
            ></v-text-field>

            <v-text-field
              v-model="password"
              :rules="passwordRules"
              label="Password"
              type="password"
              required
            ></v-text-field>
          </v-form>
          <v-card-actions class="pa-4">
            <v-btn
              color="primary"
              width="100%"
              :loading="isSendingRequest"
              @click="login"
            >
              Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
      <v-dialog v-model="showErrorDialog" max-width="400">
        <v-card>
          <v-card-title class="flex-column">
            <v-icon size="96" color="error" class="mb-4">error_outline</v-icon>
            <span> Error</span>
          </v-card-title>
          <v-card-text class="text-center">
            {{ showErrorMessage }}
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn color="secondary" text @click="() => setErrorData()"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>
<style scoped>
.background {
  background: rgb(64, 106, 255);
  background: linear-gradient(96.32deg, #4e62c9 -14.21%, #d361b7 102.68%);
}

.logo {
  max-height: 40px;
}
</style>
<style>
html {
  overflow-y: auto;
}
</style>
