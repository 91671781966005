import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'md'
  },
  theme: {
    light: true,
    themes: {
      light: {
        primary: '#406AFF',
        secondary: '#E054DB',
        accent: '#FEC840',
        error: '#FF4062',
        warning: '#FF7D1F',
        info: '#0081FF',
        success: '#4FBE9D'
      }
    }
  }
})
