import Vue from 'vue'
import vuetify from './plugins/vuetify'
import LoginApp from './LoginApp.vue'

Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(LoginApp),
}).$mount('#login-app')
